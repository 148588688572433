import {Button, Col, Form, Input, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {createBrand} from "../../../api/settings"
import {toast} from "react-toastify"
import Select from "react-select"
import {selectListData} from "../../../api/general"
import {Tooltip} from "react-tooltip"

const AddBrand = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [departmentsOptions, setDepartmentsOptions] = useState()
  const [emailConfigOptions, setEmailConfigOptions] = useState()
  const [selectedEmailConfig, setSelectedEmailConfig] = useState([])
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [aspectRatioOptions, setAspectRatioOptions] = useState([
	  {value: 51, label: '5:1'},
	  {value: 41, label: '4:1'},
	  {value: 31, label: '3:1'},
	  {value: 21, label: '2:1'},
	  {value: 32, label: '3:2'},
	  {value: 11, label: '1:1'},
  ])
  const [selectedAspectRatio, setSelectedAspectRatio] = useState([])
  const [emailTemplatesOptions, setEmailTemplatesOptions] = useState()
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState([])

  const unsubscribeTooltipeText = 'Please note: the absence of such a link may increase the risk of ' +
    'your emails being blocked by spam services, as it may raise suspicions of spam activity.'

  const submitHandler = (e) => {
    e.preventDefault()

    let data = new FormData(e.target)

    createBrand(data).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
        closeModal()
      } else {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        } else {
          toast.error(message)
        }
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  useEffect(() => {
    selectListData('departments').then(r => setDepartmentsOptions(r))
    selectListData('emails_config').then(r => setEmailConfigOptions(r))
    selectListData('emails_template').then(r => setEmailTemplatesOptions(r))
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Short Name</Label>
          <Input
            id="add_brand_short_name"
            name="short_name"
            required={true}
            placeholder="Name"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Title</Label>
          <Input
            name="title"
            id="add_brand_"
            required={true}
            placeholder="Name"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Address</Label>
          <Input
            id="add_brand_address"
            type="textarea"
            rows="5"
            name="address"
            placeholder="Address..."
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Logo</Label>
          <Input
            id="add_brand_logo"
            type="file"
            name="logo_file"
            placeholder="Add logo..."
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Email Logo URL</Label>
          <Input
            id="add_brand_email_logo"
            type="input"
            name="email_logo_url"
            placeholder="Add logo URL..."
          />
        </div>
	      <div className="form-group mb-3">
		      <Label htmlFor="choices-multiple-default" className="form-label mb-0">Mail logo aspect ratio</Label>
		      <Select
            id="add_brand_mail_aspect_select"
			      placeholder="Choose..."
			      isClearable={true}
            name="aspect_ratio"
			      onChange={(selected) => {
				      setSelectedAspectRatio(selected)
			      }}
			      options={aspectRatioOptions}
		      />
	      </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Email config</Label>
          <Select
            id="add_brand_mail_config_select"
            placeholder="Choose..."
            required={true}
            name="config_id"
            onChange={(option) => setSelectedEmailConfig(option)}
            options={emailConfigOptions}
          />
        </div>
        <div className="mb-3 d-flex align-items-end justify-content-between">
          <Col className="form-group">
            <Label className="form-label mb-0">Email template</Label>
            <Select
              id="add_brand_email_template"
              placeholder="Choose..."
              required={true}
              name="email_template"
              onChange={(option) => setSelectedEmailTemplate(option)}
              options={emailTemplatesOptions}
            />
          </Col>
          {selectedEmailTemplate?.preview_url &&
            <div className="ms-3">
              <a
                id="add_brand_preview"
                target="_blank"
                className="btn btn-primary waves-effect waves-light"
                href={selectedEmailTemplate?.preview_url}
                role="button"
              >
                Preview
              </a>
            </div>
          }
        </div>
        <div className="form-group mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Departments</Label>
          <Select
            id="add_brand_department"
            placeholder="Choose..."
            isClearable={true}
            isMulti={true}
            required={true}
            name="department_ids[]"
            onChange={(selected) => {
              setSelectedDepartments(selected)
            }}
            options={departmentsOptions}
          />
        </div>
        <div className='d-flex mt-3 mb-3'>
          <div className="form-check form-switch form-switch-left form-switch-sm">
            <Input
              className="form-check-input"
              name="send_from_employee"
              type="checkbox"
              id="add_brand_send_from_checkbox"
            />
            <Label className="form-check-label text-muted" for="add_brand_send_from_checkbox">
              Send from employee
            </Label>
          </div>

          <div className="form-check form-switch form-switch-left form-switch-sm ms-3">
            <Input
              className="form-check-input"
              name="disable_unsubscribe_link"
              type="checkbox"
              id="add_brand_unsubscribe_button"
            />
            <Label className="form-check-label text-muted" for="add_brand_unsubscribe_button">
              Hide unsubscribe link
            </Label>
            <span>
              <i
                className="ri-error-warning-line text-primary align-middle fs-20 ms-1 mt-0 pt-0"
                style={{cursor: 'help', lineHeight: "1"}}
                data-tooltip-id={'unsubscribe'}
                data-tooltip-content={unsubscribeTooltipeText}
              ></i>
              <Tooltip id={'unsubscribe'} style={{maxWidth: '300px'}}/>
            </span>
        </div>
      </div>
      <div className="hstack justify-content-end">
        <Button type="button" id="add_brand_close_modal_button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
        <Button type="submit" id="add_brand_save_button" color="primary" className="btn">Save</Button>
      </div>
    </Form>
</>
)
}

export default AddBrand
