import React, {useState} from "react"
import { ResizableBox } from "react-resizable"
import Draggable from 'react-draggable'
import "react-resizable/css/styles.css"
import styled from "styled-components"
import { Link } from "react-router-dom"

const ResizableModalContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  border-radius: 7px;
  box-shadow: 0 0 20px 3px rgba(27, 27, 27, 0.39);
`;

const ResizableModalHeader = styled.div`
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  cursor: move;
`;

const ResizableModalBody = styled.div`
  padding: 20px;
  overflow: auto;
`;

const ResizableModal = ({ title, content, onClose }) => {
  const [modalWidth, setModalWidth] = useState(400)
  const [modalHeight, setModalHeight] = useState(400)

  const onResize = (event, { size }) => {
    setModalWidth(size.width)
    setModalHeight(size.height)
  }

  return (
    <Draggable handle=".handle">
      <ResizableBox
        style={{ position: "absolute", top: "50px", left: '50px', zIndex: '2000' }}
        width={modalWidth}
        height={modalHeight}
        minConstraints={[300, 200]}
        onResize={onResize}
        resizeHandles={['se']}
      >
        <ResizableModalContainer className="bg-light w-100">
          <ResizableModalHeader className="handle d-flex justify-content-between align-items-center bg-primary px-3">
            <div className="text-truncate text-white">{title}</div>
            <Link to="#" onClick={onClose} className="fs-22 p-2 text-white" style={{ float: 'right' }}>&times;</Link>
          </ResizableModalHeader>
          <ResizableModalBody>
            <p
              className="keep-lines"
              dangerouslySetInnerHTML={{__html: content}}
            />
          </ResizableModalBody>
        </ResizableModalContainer>
      </ResizableBox>
    </Draggable>
  )
}

export default ResizableModal
